import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index.js'
import request from '@/utils/request'
import directive from '@/directive/index.js'
// import '../mock/index.js'

Vue.use(element)
Vue.prototype.$http = request
Vue.config.productionTip = false

Vue.filter('showTooltip', (msg, width) => {
  let app = document.querySelector('#app')
  // 这里需要用span，div默认是父盒子的宽度
  let span = document.createElement('span')
  span.innerHTML = msg
  // 必须要添加到DOM树里面才有宽度，太狗了。所以对性能应该是有所影响的
  // 如果要优化肯定就在这里优化了，目前我还没有想到解决办法
  // 小弟愚钝，不知有没有大哥评论里面给个解决办法
  // 或者给一个全新的办法解决
  app.appendChild(span)
  let isShow = span.offsetWidth > width
  // 新增了当然要把他咔嚓掉嘛
  app.removeChild(span)
  return !isShow
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
