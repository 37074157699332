import Vue from 'vue'
import axios from 'axios'
import storage from '@/utils/storage'
import router from '@/router'

const request = axios.create()

request.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// 请求拦截器
request.interceptors.request.use(async config => {
  let token = storage.getToken()
  let now = new Date().getTime()
  // 判断是否存在token，如果存在的话，则每个http header都加上token
  if (token) { //条件这么写是因为在login.vue的页面把token存入了localStorage的wxToken中
    // 刷新token
    if(now > token.refresh_at){
      storage.clear()
      Vue.prototype.$confirm('登录过期，请重新登录', '提示', {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        router.push('/login')
      })
    }
    if (token.expired_at < now && now < token.refresh_at && config.url !== '/api/logout') {
      await axios({
        url: '/api/refresh',
        method: 'post',
        headers: { Authorization: 'Bearer ' + token.access_token }
      }).then(response => {
        token = response.data.data
        storage.setToken(token)
      })
    }
    config.headers.Authorization = 'Bearer ' + token.access_token
  }
  let name = router.history.current.name
  if(config.url == '/api/logout'){
     return config
  }
  else if(name == 'login'){
    return config
  }else{
    if(storage.getUser().type === 0){
      if(storage.getFree().indexOf(name) != -1){
        return config
      }else{
        return false
      }
    }else if(storage.getUser().type === 1){
      if(storage.getBase().concat(storage.getFree()).indexOf(name) != -1){
        return config
      }else{
        return false
      }
    } else if(storage.getUser().type === 2){
      if(storage.getPro().concat(storage.getBase()).concat(storage.getFree()).indexOf(name) != -1){
        return config
      }else{
        return false
      }
    } else{
      return config
    }
  }
   // 赋值完后把config返回回去
}, error => {
  // 请求错误后把我们的error返回回去
  return Promise.reject(error)
})
// 响应拦截器
request.interceptors.response.use(response => {
  return response.data
}, error => {
  if (error.response) {
    let res = error.response.data
    if (res.err_code === 'UNAUTHORISED') {
      storage.clear()
      Vue.prototype.$confirm('登录过期，请重新登录', '提示', {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        router.push('/login')
      })
    } else {
      Vue.prototype.$confirm(res.err_msg, res.err_code, {
        showCancelButton: false,
        type: 'error'
      })
    }
  } else if (error.request) {
    console.log(error.request)
  } else {
    console.log('Error', error.message, error)
  }
})
export default request
