import Vue from 'vue'
import VueRouter from 'vue-router'
import '@/style/public.css'
import storage from '@/utils/storage'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import ( /* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/agree',
    component: () =>
      import ( /* webpackChunkName: "login" */ '@/views/agree/index.vue'),
    children:[
      {
        path: '/agree',
        name: 'agree',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/agree/agree.vue'),
        meta: {
          title: '用户服务协议'
        }
      },
      {
        path: '/conceal',
        name: 'conceal',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/agree/conceal.vue'),
        meta: {
          title: '隐私政策'
        }
      },
      {
        path: '/pages/agree/conceal',
        name: 'agreeConceal',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/agree/conceal.vue'),
        meta: {
          title: '隐私政策'
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/agree/contact.vue'),
        meta: {
          title: '联系我们'
        }
      },
    ]
  },
  {
    path: '/',
    // name: 'layout',
    component: () =>
      import ( /* webpackChunkName: "about" */ '@/layout/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/home/index.vue'),
        meta: {
          title: '首页'
        }

      },

      {
        path: '/search',
        name: 'search',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/search/index.vue'),
        meta: {
          title: '商标搜索'
        },
        children: [
          {
            path: '/search',
            name: 'searchList',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/search/searchList.vue'),
            meta: {
              title: '商标搜索结果'
            },
          },
          {
            path: '/search/details',
            name: 'searchDetails',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/search/searchDetails.vue'),
            meta: {
              title: '商标搜索详情'
            },
          },
        ]

      },
      {
        path: '/trademark',
        name: 'trademark',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/trademark/index.vue'),
        meta: {
          title: '商标注册'
        }

      },
      {
        path: '/copyServices',
        name: 'copyServices',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/copyServices/index.vue'),
        meta: {
          title: '版权服务'
        }

      },
      {
        path: '/patentServices',
        name: 'patentServices',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/patentServices/index.vue'),
        meta: {
          title: '专利服务'
        }

      },
      {
        path: '/trademarkClass',
        name: 'trademarkClass',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/trademarkClass/index.vue'),
        meta: {
          title: '商标分类'
        }

      },
      {
        path: '/helpCenter',
        name: 'helpCenter',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/helpCenter/index.vue'),
        meta: {
          title: '帮助中心'
        }

      },
      {
        path: '/about',
        name: 'about',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/about/index.vue'),
        meta: {
          title: '关于我们'
        }
      },
    ],
  },
  {
    path: '/personal/',
    name: 'personal',
    component: () =>
      import ( /* webpackChunkName: "about" */ '@/components/personal/personal.vue'),
    meta: {
      title: '首页',
      breadcrumb: '首页',
    },
    children: [
      {
        path: '/personal/',
        name: 'personalHome',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/personal/home.vue'),
        meta: {
          title: '首页',
          breadcrumb: '',
        },
      },

      // {
      //   path: '/draggable/',
      //   name: 'draggable',
      //   component: () =>
      //     import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/draggable.vue'),
      //   meta: {
      //     title: '水印',
      //     breadcrumb: '',
      //   },
      // },
      {
        path: '/personal/query/',
        name: 'query',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/query/index.vue'),
        meta: {
          title: '商标查询',
          breadcrumb: '商标查询',
        }

      },
      {
        path: '/personal/query/queryKeyword/:type/:keyword',
        name: 'queryKeyword',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/query/keyword.vue'),
        meta: {
          title: '关键字搜索',
          breadcrumb: '关键字搜索',
        }

      },
      {
        path: '/personal/query/queryDetail/:id',
        name: 'queryDetail',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/query/detail.vue'),
        meta: {
          title: '商标详情',
          breadcrumb: '商标详情',
        }

      },
      {
        path: '/personal/query/queryIntcls/',
        name: 'queryIntcls',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/query/intcls.vue'),
        meta: {
          title: '商标类别',
          breadcrumb: '商标类别',
        }

      },
      {
        path: '/personal/query/queryClass/:intcls',
        name: 'queryClass',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/query/class.vue'),
        meta: {
          title: '商标类别',
          breadcrumb: '商标类别',
        }

      },
      {
        path: '/personal/query/queryGoods/:keyword',
        name: 'queryGoods',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/query/goods.vue'),
        meta: {
          title: '商标类别',
          breadcrumb: '商标类别',
        }

      },
      {
        path: '/personal/dealtMatter/',
        name: 'dealtMatter',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/dealtMatter/index.vue'),
        meta: {
          title: '待办事项',
          breadcrumb: '待办事项',
        },
      },
      {
        path: '/personal/dealtMatterDetail/',
        name: 'dealtMatterDetail',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/dealtMatter/dealtMatterDetail.vue'),
        meta: {
          title: '待办事项',
          breadcrumb: '待办事项',
        },
      },
      {
        path: '/personal/personal/',
        name: 'perindex',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/personal/index.vue'),
        meta: {
          title: '订单管理',
          breadcrumb: '订单管理',
        },
      },
      {
        path: '/personal/toPaid',
        name: 'toPaid',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/personal/toPaid.vue'),
      },
      {

        path: '/personal/personal/myApplication',
        name: 'myApplication',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/personal/myApplication.vue'),
        meta: {
          title: '我的申请',
          breadcrumb: '我的申请',
        },
      },
      {

        path: '/personal/myApplication/applicationDetails',
        name: 'applicationDetails',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/personal/applicationDetails.vue'),
        meta: {},
      },
      {
        path: '/personal/apply',
        name: 'applyAdmin',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/apply/index.vue'),
        meta: {
          title: '委托业务',
          breadcrumb: '委托业务',
        },
        children: [
          {
            path: '/personal/apply/',
            name: 'apply',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/apply.vue'),
            meta: {
              title: '委托业务-国内商标申请',
              breadcrumb: '国内商标申请',
            },
          },
          {
            path: '/personal/apply/international',
            name: 'international',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/international.vue'),
            meta: {
              title: '委托业务-国际商标申请',
              breadcrumb: '国际商标申请',
            },
          },
          {
            path: '/personal/apply/works',
            name: 'applyWorks',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/works.vue'),
            meta: {
              title: '委托业务-著作权登记',
              breadcrumb: '著作权登记',
            },
          },
          {
            path: '/personal/apply/applyDetail/:id',
            name: 'applyDetail',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/applyDetail.vue'),
            meta: {
              title: '国内商标申请详情',
              breadcrumb: '国内商标申请',
            },

          },
          {
            path: '/personal/apply/internationalDetail/:id',
            name: 'internationalDetail',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/internationalDetail.vue'),
            meta: {
              title: '国际商标申请详情',
              breadcrumb: '国际商标申请',
            },

          },
          {
            path: '/personal/apply/worksDetail/:id',
            name: 'worksDetail',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/worksDetail.vue'),
            meta: {
              title: '著作权登记详情',
              breadcrumb: '著作权登记',
            },
          },
          {
            path: '/personal/apply/litigation',
            name: 'litigation',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/litigation.vue'),
            meta: {
              title: '委托业务-行政诉讼',
              breadcrumb: '行政诉讼',
            },
          },
          {
            path: '/personal/apply/litigationDetail/:id',
            name: 'litigationDetail',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/litigationDetail.vue'),
            meta: {
              title: '行政诉讼申请详情',
              breadcrumb: '行政诉讼',
            },
          },
          {
            path: '/personal/apply/bills',
            name: 'bills',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/bill.vue'),
            meta: {
              title: '委托业务-账单信息',
              breadcrumb: '账单信息',
            }
          },
          {
            path: '/personal/apply/billDetail/:id',
            name: 'billDetail',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/apply/billDetail.vue'),
            meta: {
              title: '委托业务-账单详情',
              breadcrumb: '账单详情',
            }
          },
        ]
      },
      {
        path: '/personal/autonomy',
        name: 'autonomy',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/autonomy/index.vue'),
        meta: {
          title: '办理',
          breadcrumb: '办理',
        },

      },
      {
        path: '/personal/applicants',
        name: 'applicants',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/applicants/index.vue'),
        meta: {
          title: '常用申请人',
          breadcrumb: '常用申请人',
        },

      },
      
      {
        path: '/personal/entrusted',
        name: 'entrusted',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/entrusted/index.vue'),
        meta: {
          title: '委托业务',
          breadcrumb: '委托业务',
        },
      },
      {
        path: '/personal/handling',
        name: 'handling',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/handling/index.vue'),
        meta: {
          title: '自助办理',
          breadcrumb: '自助办理',
        },
        children: [
          {
            path: '/personal/handling',
            name: 'register',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/handling/register.vue'),
            meta: {
              title: '自助办理-填写商标信息',
              breadcrumb: '填写商标信息',
            },
          },
          {
            path: '/personal/handling/fllApplicant',
            name: 'fllApplicant',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/handling/fllApplicant.vue'),
            meta: {
              title: '自助办理-填写申请人信息',
              breadcrumb: '填写申请人信息',
            },
          },
          {
            path: '/personal/handling/payOrder',
            name: 'payOrder',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/handling/payOrder.vue'),
            meta: {
              title: '自助办理-支付订单费用',
              breadcrumb: '支付订单费用',
            },
          },
          {
            path: '/personal/handling/cashier',
            name: 'cashier',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/handling/cashier.vue'),
            meta: {
              title: '自助办理-收银台',
              breadcrumb: '收银台',
            },
          },
        ]

      },
      {
        path: '/personal/Invoice',
        name: 'Invoice',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/Invoice/index.vue'),
        meta: {
          title: '发票管理',
          breadcrumb: '发票管理',
        },
        children: [
          {
            path: '/personal/Invoice',
            name: 'InvoiceInfor',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/Invoice/InvoiceInfor.vue'),
            meta: {
              title: '发票管理-发票信息',
              breadcrumb: '发票信息',
            },

          },
          {
            path: '/personal/Invoice/InvoiceElect',
            name: 'InvoiceElect',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/Invoice/InvoiceElect.vue'),
            meta: {
              title: '发票管理-电子发票',
              breadcrumb: '电子发票',

            },

          },
          {
            path: '/personal/Invoice/InvoicePaper',
            name: 'InvoicePaper',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/Invoice/InvoicePaper.vue'),
            meta: {
              title: '发票管理-纸质发票',
              breadcrumb: '纸质发票',
            },

          },

        ]
      },
      {
        path: '/personal/trademarkAdmin/',
        name: 'Intellectual',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/index.vue'),
        meta: {
          title: '国内商标',
          breadcrumb: '国内商标',
        },
        children: [
          {
            path: '/personal/trademarkAdmin/',
            name: 'domestic',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/trademark.vue'),
            meta: {
              title: '国内商标-自有商标',
              breadcrumb: '自有商标',
            }
          },
          {
            path: '/personal/review/',
            name: 'review',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/review.vue'),
            meta: {
              title: '国内商标-评审商标',
              breadcrumb: '评审商标',
            }
          },
          {
            path: '/personal/objection/',
            name: 'objection',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/objection.vue'),
            meta: {
              title: '国内商标-异议商标',
              breadcrumb: '异议商标',
            }
          },
          {
            path: '/personal/atlas',
            name: 'atlas',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/atlas.vue'),
            meta: {
              title: '国内商标-商标图谱',
              breadcrumb: '商标图谱',
            }
          },
          {
            path: '/personal/risk/',
            name: 'risk',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/risk.vue'),
            meta: {
              title: '国内商标-商标监测',
              breadcrumb: '商标监测',
            }
          },
          {
            path: '/personal/trademarkAdmin/trademarkArchives',
            name: 'archives',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/trademarkArchives.vue'),
            meta: {
              title: '国内商标-商标官文',
              breadcrumb: '商标官文',
            },
          },
          {
            path: '/personal/trademarkAdmin/maintain',
            name: 'maintain',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/maintain.vue'),
            meta: {
              title: '商标管理-干预商标',
              breadcrumb: '干预商标',
            }
          },
          {
            path: '/personal/trademarkAdmin/maintainArchives',
            name: 'maintainArchives',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/maintainArchives.vue'),
            meta: {
              title: '商标管理-干预商标档案',
              breadcrumb: '干预商标档案',
            },
          },
          {
            path: '/personal/trademarkAdmin/upload',
            name: 'upload',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/upload.vue'),
            meta: {
              title: '商标管理-上传商标档案',
              breadcrumb: '上传商标档案',
            },
          },
          {
            path: '/personal/evidence',
            name: 'evidence',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/evidence.vue'),
            meta: {
              title: '国内商标-使用证据',
              breadcrumb: '使用证据',
            },
          },
          {
            path: '/personal/evidUpload',
            name: 'evidUpload',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/evidUpload.vue'),
            meta: {
              title: '国内商标-上传使用证据',
              breadcrumb: '上传使用证据',
            },
          },
          {
            path: '/personal/domestic/domesticDetails/:tmcode/:intcls',
            name: 'domesticDetails',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/domesticDetails.vue'),
            meta: {
              title: '商标详情',
              breadcrumb: '商标详情',
            }

          },
          {
            path: '/personal/domestic/tradDetails/:tmcode/:intcls/:alias',
            name: 'tradDetails',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/tradDetails.vue'),
            meta: {
              title: '自有商标详情',
              breadcrumb: '自有商标详情',
            }
          },
          {
            path: '/personal/domestic/reviewDetails/:tmcode/:intcls',
            name: 'reviewDetails',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/reviewDetails.vue'),
            meta: {
              title: '评审商标详情',
              breadcrumb: '评审商标详情',
            }
          },
          {
            path: '/personal/domestic/objectionDetails/:tmcode/:intcls',
            name: 'objectionDetails',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/trademarkAdmin/objectionDetails.vue'),
            meta: {
              title: '异议商标详情',
              breadcrumb: '异议商标详情',
            }
          },
        ]
      },
      {
        path: '/personal/interMark/',
        name: 'interMark',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/interMark/index.vue'),
        meta: {
          title: '国际商标',
          breadcrumb: '国际商标',
        },
        children:[
          {
            path: '/personal/interMark/',
            name: 'interMarkDoc',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/interMark/trademark.vue'),
            meta: {
              title: '国际商标-自有商标',
              breadcrumb: '自有商标',
            }
          },
          {
            path: '/personal/interMark/detail/:id',
            name: 'interMarkDetali',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/interMark/detail.vue'),
            meta: {
              title: '国际商标详情',
              breadcrumb: '国际商标详情',
            }
          },

          {
            path: '/interMark/atlas',
            name: 'interMarkAtlas',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/interMark/atlas.vue'),
            meta: {
              title: '国际商标-商标图谱',
              breadcrumb: '商标图谱',
            }
          },
        ]
      },

      {
        path: '/personal/parentAdmin/',
        name: 'patent',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/parentAdmin/patent.vue'),
        meta: {
          title: '专利',
          breadcrumb: '专利',
        }
      },
      {
        path: '/personal/parentAdmin/patentDetails/:id',
        name: 'patentDetails',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/parentAdmin/patentDetails.vue'),
        meta: {
          title: '专利-专利详情',
          breadcrumb: '专利详情',
        }
      },
      {
        path: '/personal/copyrightAdmin/',
        name: 'copyrightAdmin',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/copyrightAdmin/index.vue'),
        meta: {
          title: '著作权',
          breadcrumb: '著作权',
        },
        children: [
          {
            path: '/personal/copyrightAdmin/',
            name: 'works',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/copyrightAdmin/works.vue'),
            meta: {
              title: '著作权-作品著作权',
              breadcrumb: '作品著作权',
            }
          },

          {
            path: '/personal/copyrightAdmin/software',
            name: 'software',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/copyrightAdmin/software.vue'),
            meta: {
              title: '著作权-软件著作权',
              breadcrumb: '软件著作权',
            }
          },
          {
            path: '/personal/copyrightAdmin/worksArchives',
            name: 'work',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/copyrightAdmin/worksArchives.vue'),
            meta: {
              title: '著作权-著作权官文',
              breadcrumb: '著作权官文',
            },
          },
          // {
          //   path: '/personal/copyrightAdmin/softwareArchives',
          //   name: 'softwares',
          //   component: () =>
          //     import ( /* webpackChunkName: "about" */ '@/views/copyrightAdmin/softwareArchives.vue'),
          //   meta: {
          //     title: '著作权-软件著作权官文',
          //     breadcrumb: '软件著作权官文',
          //   },
          // },
          {
            path: '/personal/copyrightAdmin/worksDetails/:id',
            name: 'worksDetails',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/copyrightAdmin/worksDetails.vue'),
            meta: {
              title: '著作权-作品著作权详情',
              breadcrumb: '作品著作权详情',
            }
          },
        ]
      },
      {
        path: '/personal/domestic/websiteFiling',
        name: 'websiteFiling',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/websiteAdmin/websiteFiling.vue'),
        meta: {
          title: '网站备案',
          breadcrumb: '网站备案',
        }
      },
      {
        path: '/personal/approval',
        name: 'approval',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/approval/index.vue'),
        meta: {
          title: '流程审批',
          breadcrumb: '流程审批',
        },
        children:[
          {
            path: '/personal/approval/',
            name: 'approvalList',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/approval/list.vue'),
            meta: {
              title: '流程审批-流程审批',
              breadcrumb: '流程审批',
            }
          },
          {
            path: '/personal/approval/form',
            name: 'approvalForm',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/approval/setForm.vue'),
            meta: {
              title: '流程审批-表单设置',
              breadcrumb: '表单设置',
            }
          },
          {
            path: '/personal/approval/contacts',
            name: 'approvalContacts',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/approval/setApproval.vue'),
            meta: {
              title: '流程审批-审批人设置',
              breadcrumb: '审批人设置',
            }
          },
        ]
      },
      {
        path: '/personal/newlyBuild/:id',
        name: 'newlyBuild',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/applicants/newlyBuild.vue'),
        meta: {
          title: '申请人信息',
          breadcrumb: '申请人信息',
        },
      },
      {
        path: '/personal/myMessage',
        name: 'myMessage',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/myMessage/index.vue'),
        meta: {
          title: '账户设置',
          breadcrumb: '账户设置',
        },
      },
      {
        path: '/personal/myMessageCenter',
        name: 'myMessageCenter',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/myMessage/myMessageCenter.vue'),
        meta: {
          title: '消息中心',
          breadcrumb: '消息中心',
        },
      },
      {
        path: '/personal/dowmload/',
        name: 'dowmload',
        component: () =>
          import ( /* webpackChunkName: "about" */ '@/views/download/index.vue'),
        meta: {
          title: '帮助中心',
          breadcrumb: '帮助中心',
        },
        children: [
          {
            path: '/personal/journal/',
            name: 'journal',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/download/journal.vue'),
            meta: {
              title: '帮助中心-更新日志',
              breadcrumb: '更新日志',
            },
          },
          {
            path: '/personal/journalDetail/:id',
            name: 'journalDetail',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/download/journalDetail.vue'),
            meta: {
              title: '帮助中心-更新日志详情',
              breadcrumb: '更新日志详情',
            },
          },
          {
            path: '/personal/client/',
            name: 'client',
            component: () =>
              import ( /* webpackChunkName: "about" */ '@/views/download/client.vue'),
            meta: {
              title: '帮助中心-下载客户端',
              breadcrumb: '下载客户端',
            },
          },

          // {
          //   path: '/personal/data/',
          //   name: 'data',
          //   component: () =>
          //     import ( /* webpackChunkName: "about" */ '@/views/download/data.vue'),
          //   meta: {
          //     title: '帮助中心-大数据',
          //     breadcrumb: ' 大数据',
          //   },
          // }

        ]
      },
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  routes,
})
router.beforeEach((to, from, next) => {
  // 设置标签title
  document.title = '知者云 - ' + to.meta.title
  // 个人中心登录校验
  let path = to.path.split('/')[1]
  let token = storage.getToken()
  let now = new Date().getTime()
  let isLogin = token && now < token.expired_at
  if (path === 'personal') {
    // 检查token是否过期

    if (isLogin) {
      if (!storage.getUser().nickname || !storage.getUser().company || !storage.getUser().email) {
        if (to.name === 'myMessage') {
          next()
        } else {
          next({path: '/personal/myMessage'})
          Vue.prototype.$confirm('账户信息不完全，请补充完整', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        }
      } else {
        next()
      }
    } else {
      storage.clear()
      next({name: 'login'})
    }
  } else if (path === 'login') {
    if (isLogin) {
      next({name: 'personalHome'})
    } else {
      next()
    }
  } else if (path === '') {
    next({name: 'personalHome'})
  } else {
    next()
  }
})
export default router
