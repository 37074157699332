import Vue from 'vue'
import Vuex from 'vuex'
let u = navigator.userAgent;
let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || !!u.match(/Macintosh/i) || !!u.match(/MacIntel/i);
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    heights: u.indexOf('AppleWebKit') != -1 && u.indexOf('Chrome') == -1 ? true : false,
    isCollapse: (document.body.offsetWidth <= 1280) ? true : false,
    tms:'',
    user:'',
    scroll:'',
    token:'',
  },
  mutations: {

    setterCollapse: (state, payload) => {
      state.isCollapse = payload.isCollapse;
    },
    settms:(state, payload) =>{
      state.tms = payload.tms
    },
    setuser:(state, payload) =>{
      state.user = payload.user
    },
    setscroll:(state, payload) =>{
      state.scroll = payload.scroll
    },
    settoken:(state, payload) =>{
      state.token = payload.token
    }
  },
  actions: {

  },
  modules: {
  }
})
