import Vue from 'vue'
import {Popconfirm,Card,Loading ,Alert,DropdownItem,DropdownMenu,Dropdown ,Slider,Calendar,Notification ,Tabs,TabPane,Badge ,Row,Col,Collapse,CollapseItem ,Breadcrumb,BreadcrumbItem,Step,Steps,MenuItem,MenuItemGroup,Submenu,Menu,Aside,Form, FormItem,Tag, Tooltip,Autocomplete,RadioButton, RadioGroup, Radio, Switch, Empty, Skeleton, SkeletonItem, Table, Button, Input, TableColumn, DatePicker, Pagination,Select, Option, Message, MessageBox, Image, Checkbox, CheckboxGroup, Carousel, CarouselItem, Dialog, Upload } from 'element-ui'
const element = {
    install: function(Vue) {
        Vue.use(Popconfirm)
        Vue.use(Card)
        Vue.use(Loading)
        Vue.use(Alert)
        Vue.use(DropdownItem)
        Vue.use(DropdownMenu)
        Vue.use(Dropdown)
        Vue.use(Slider)
        Vue.use(Calendar)
        Vue.use(TabPane)
        Vue.use(Tabs)
        Vue.use(Badge)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(Breadcrumb)
        Vue.use(BreadcrumbItem)
        Vue.use(Step)
        Vue.use(Steps)
        Vue.use(MenuItem)
        Vue.use(MenuItemGroup)
        Vue.use(Submenu)
        Vue.use(Menu)
        Vue.use(Aside)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Table)
        Vue.use(TableColumn)
        Vue.use(Button)
        Vue.use(Input)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(DatePicker)
        Vue.use(Pagination)
        Vue.use(Image)
        Vue.use(Checkbox)
        Vue.use(CheckboxGroup)
        Vue.use(Carousel)
        Vue.use(CarouselItem)
        Vue.use(Dialog)
        Vue.use(Upload)
        Vue.use(Skeleton)
        Vue.use(SkeletonItem)
        Vue.use(Empty)
        Vue.use(Switch)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(RadioButton)
        Vue.use(Autocomplete)
        Vue.use(Tooltip)
        Vue.use(Tag)
    }
}
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
export default element