import request from '@/utils/request'

const USER = 'user'
const TOKEN = 'token'
const SELECTS = 'selects'
const VERSION = 'version'
const CHECKED = 'checked'
const storage = {
  // 存键值
  setItem (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  // 读取键值或空值
  getItem (key) {
    let value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  },
  // 清除键值
  removeItem (key) {
    localStorage.removeItem(key)
  },
  // 清除本地存储
  clear () {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('selects')
    // localStorage.clear()
  },
  // 协议
  setChecked(value) {
    this.setItem(CHECKED, value)
  },
  getChecked() {
    return this.getItem(CHECKED)
  },
  // 版本号
  setVersion (value) {
    this.setItem(VERSION, value)
  },
  getVersion () {
    return this.getItem(VERSION)
  },
  // 令牌
  setToken (value) {
    this.setItem(TOKEN, value)
  },
  getToken () {
    return this.getItem(TOKEN)
  },
  // 用户信息
  setUser (value) {
    this.setItem(USER, value)
  },
  // 读用户信息
  getUser () {
    return this.getItem(USER)
  },
  /**
   * 获取国际分类数据
   * @returns Array
   */
  getType(){
    return  [
      '商标初步审定公告',
      '商标注册公告(一)',
      '商标注册公告(二)',
      '注册商标续展公告',
      '商标注册人/申请人名义及地址变更公告',
     ' 变更集体/证明商标申请人名义地址/管理规则成员名单公告',
      '商标转让/移转公告',
      '送达公告',
      '注册商标注销公告',
      '注册商标撤销公告',
      '无效公告',
      '商标注册申请撤回公告',
      '商标使用许可备案公告',
      '变更商标代理机构公告',
      '商标注册证遗失声明公告',
      '注册商标宣告无效公告',
      '商标质权登记公告',
      '注册商标未续展注销公告',
      '商标更正公告',
      '集体商标初步审定公告',
      '证明商标注册公告',
    ]
  },
  getIntcls () {
    return [
      { id: '01', text: '01类 - 化工原料' },
      { id: '02', text: '02类 - 油漆涂料' },
      { id: '03', text: '03类 - 化妆清洁' },
      { id: '04', text: '04类 - 工业油脂' },
      { id: '05', text: '05类 - 药品制剂' },
      { id: '06', text: '06类 - 五金器具' },
      { id: '07', text: '07类 - 机械机器' },
      { id: '08', text: '08类 - 手工用具' },
      { id: '09', text: '09类 - 电子电器' },
      { id: '10', text: '10类 - 医疗器械' },
      { id: '11', text: '11类 - 电器装置' },
      { id: '12', text: '12类 - 运输工具' },
      { id: '13', text: '13类 - 军火烟火' },
      { id: '14', text: '14类 - 珠宝钟表' },
      { id: '15', text: '15类 - 各种乐器' },
      { id: '16', text: '16类 - 办公用品' },
      { id: '17', text: '17类 - 橡胶制品' },
      { id: '18', text: '18类 - 皮革皮具' },
      { id: '19', text: '19类 - 建筑材料' },
      { id: '20', text: '20类 - 家具装饰' },
      { id: '21', text: '21类 - 家用器具' },
      { id: '22', text: '22类 - 绳网袋篷' },
      { id: '23', text: '23类 - 纺织纱线' },
      { id: '24', text: '24类 - 布料床品' },
      { id: '25', text: '25类 - 服装鞋帽' },
      { id: '26', text: '26类 - 服装辅料' },
      { id: '27', text: '27类 - 地毯席垫' },
      { id: '28', text: '28类 - 娱乐器具' },
      { id: '29', text: '29类 - 肉鱼蛋奶' },
      { id: '30', text: '30类 - 方便食品' },
      { id: '31', text: '31类 - 植物种籽' },
      { id: '32', text: '32类 - 啤酒饮料' },
      { id: '33', text: '33类 - 含酒精饮料' },
      { id: '34', text: '34类 - 烟草烟具' },
      { id: '35', text: '35类 - 广告销售' },
      { id: '36', text: '36类 - 金融不动产' },
      { id: '37', text: '37类 - 安装维修' },
      { id: '38', text: '38类 - 通讯服务' },
      { id: '39', text: '39类 - 运输储藏' },
      { id: '40', text: '40类 - 材料加工' },
      { id: '41', text: '41类 - 教育娱乐' },
      { id: '42', text: '42类 - 技术服务' },
      { id: '43', text: '43类 - 餐饮住宿' },
      { id: '44', text: '44类 - 医疗园艺' },
      { id: '45', text: '45类 - 社会服务' },
    ]
  },
  getInicon(){
    return [
      {icon:'icon-cls01', value:0, text: '01类 - 化工原料' },
      {icon:'icon-cls02', value:0, text: '02类 - 油漆涂料' },
      {icon:'icon-cls03', value:0, text: '03类 - 化妆清洁' },
      {icon:'icon-cls04', value:0, text: '04类 - 工业油脂' },
      {icon:'icon-cls05', value:0, text: '05类 - 药品制剂' },
      {icon:'icon-cls06', value:0, text: '06类 - 五金器具' },
      {icon:'icon-cls07', value:0, text: '07类 - 机械机器' },
      {icon:'icon-cls08', value:0, text: '08类 - 手工用具' },
      {icon:'icon-cls09', value:0, text: '09类 - 电子电器' },
      {icon:'icon-cls10', value:0, text: '10类 - 医疗器械' },
      {icon:'icon-cls11', value:0, text: '11类 - 电器装置' },
      {icon:'icon-cls12', value:0, text: '12类 - 运输工具' },
      {icon:'icon-cls13', value:0, text: '13类 - 军火烟火' },
      {icon:'icon-cls14', value:0, text: '14类 - 珠宝钟表' },
      {icon:'icon-cls15', value:0, text: '15类 - 各种乐器' },
      {icon:'icon-cls16', value:0, text: '16类 - 办公用品' },
      {icon:'icon-cls17', value:0, text: '17类 - 橡胶制品' },
      {icon:'icon-cls18', value:0, text: '18类 - 皮革皮具' },
      {icon:'icon-cls19', value:0, text: '19类 - 建筑材料' },
      {icon:'icon-cls20', value:0, text: '20类 - 家具装饰' },
      {icon:'icon-cls21', value:0, text: '21类 - 家用器具' },
      {icon:'icon-cls22', value:0, text: '22类 - 绳网袋篷' },
      {icon:'icon-cls23', value:0, text: '23类 - 纺织纱线' },
      {icon:'icon-cls24', value:0, text: '24类 - 布料床品' },
      {icon:'icon-cls25', value:0, text: '25类 - 服装鞋帽' },
      {icon:'icon-cls26', value:0, text: '26类 - 服装辅料' },
      {icon:'icon-cls27', value:0, text: '27类 - 地毯席垫' },
      {icon:'icon-cls28', value:0, text: '28类 - 娱乐器具' },
      {icon:'icon-cls29', value:0, text: '29类 - 肉鱼蛋奶' },
      {icon:'icon-cls30', value:0, text: '30类 - 方便食品' },
      {icon:'icon-cls31', value:0, text: '31类 - 植物种籽' },
      {icon:'icon-cls32', value:0, text: '32类 - 啤酒饮料' },
      {icon:'icon-cls33', value:0, text: '33类 - 含酒精饮料' },
      {icon:'icon-cls34', value:0, text: '34类 - 烟草烟具' },
      {icon:'icon-cls35', value:0, text: '35类 - 广告销售' },
      {icon:'icon-cls36', value:0, text: '36类 - 金融不动产' },
      {icon:'icon-cls37', value:0, text: '37类 - 安装维修' },
      {icon:'icon-cls38', value:0, text: '38类 - 通讯服务' },
      {icon:'icon-cls39', value:0, text: '39类 - 运输储藏' },
      {icon:'icon-cls40', value:0, text: '40类 - 材料加工' },
      {icon:'icon-cls41', value:0, text: '41类 - 教育娱乐' },
      {icon:'icon-cls42', value:0, text: '42类 - 技术服务' },
      {icon:'icon-cls43', value:0, text: '43类 - 餐饮住宿' },
      {icon:'icon-cls44', value:0, text: '44类 - 医疗园艺' },
      {icon:'icon-cls45', value:0, text: '45类 - 社会服务' },
    ]
  },
  getTrademarkTypes () {
    return [
      { id: '01', text: '商标注册证' },
      { id: '02', text: '初步审定公告通知书' },
      { id: '03', text: '商标注册申请受理通知书' },
      { id: '04', text: '商标驳回通知书' },
      { id: '05', text: '商标部分驳回通知书' },
      { id: '06', text: '注册商标变更证明' },
      { id: '07', text: '商标转让证明' },
      { id: '08', text: '商标续展注册证明' },
    ]
  },
  getReviewTypes () {
    return [
      { id: '准予注册的决定', text: '准予注册的决定' },
      { id: '不予注册的决定', text: '不予注册的决定' },
      { id: '部分不予注册的决定', text: '部分不予注册的决定' },
      { id: '驳回复审决定书', text: '驳回复审决定书' },
      { id: '撤回复审决定书', text: '撤回复审决定书' },
      { id: '无效申请裁定书', text: '无效申请裁定书' },
    ]
  },
  getObjectionTypes () {
    return [
      { id: '01', text: '准予注册的决定' },
      { id: '02', text: '不予注册的决定' },
      { id: '03', text: '部分不予注册的决定' },
    ]
  },
  getMaintainTypes () {
    return [
      {id: '1', text: '撤三的决定'},
      {id: '2', text: '撤三结案通知书'},
      {id: '3', text: '撤销复审决定书'},
      {id: '4', text: '撤销连续三年不使用注册商标申请受理通知书'},
      {id: '5', text: '异议受理通知书'},
      {id: '6', text: '异议答辩通知书'},
      {id: '7', text: '商标异议结案的通知'},
      {id: '8', text: '商标评审申请受理通知书'},
      {id: '9', text: '不予注册复审决定书'},
      {id: '10', text: '不予注册的决定'},
      {id: '11', text: '部分不予注册的决定'},
      {id: '12', text: '准予注册的决定'},
      {id: '13', text: '无效请求裁定书'},
      {id: '14', text: '证据交换通知书'},
      {id: '15', text: '评审案件答辩通知书'},
      {id: '16', text: '评审案件结案通知书'},
      {id: '17', text: '评审案件驳回通知书'},

    ]
  },
  getIntervene () {
    return [
      { id: '01', text: '撤三申请' },
      { id: '02', text: '无效宣告' },
      { id: '03', text: '无效宣告复审' },
      { id: '04', text: '撤销复审' },
      { id: '05', text: '不予注册复审' },
      { id: '06', text: '商标异议' },
    ]
  },

getCountryList(){
    return [
      {
        state:'亚洲',
        data:[
          {show:true,name:'阿联酋',short_name:'AE',value:0},
          {show:true,name:'亚美尼亚',short_name:'AM',value:0},
          {show:true,name:'文莱',short_name:'BN',value:0},
          {show:true,name:'不丹',short_name:'BT',value:0},
          {show:true,name:'格鲁吉亚',short_name:'GE',value:0},
          {show:true,name:'中国香港',short_name:'HK',value:0},
          {show:true,name:'印度尼西亚',short_name:'ID',value:0},
          {show:true,name:'以色列',short_name:'IL',value:0},
          {show:true,name:'印度',short_name:'IN',value:0},
          {show:true,name:'日本',short_name:'JP',value:0},
          {show:true,name:'吉尔吉斯斯坦',short_name:'KG',value:0},
          {show:true,name:'柬埔寨',short_name:'KH',value:0},
          {show:true,name:'韩国',short_name:'KR',value:0},
          {show:true,name:'科威特',short_name:'KW',value:0},
          {show:true,name:'老挝',short_name:'LA',value:0},
          {show:true,name:'中国澳门',short_name:'MO',value:0},
          {show:true,name:'马来西亚',short_name:'MY',value:0},
          {show:true,name:'菲律宾',short_name:'PH',value:0},
          {show:true,name:'沙特阿拉伯',short_name:'SA',value:0},
          {show:true,name:'新加坡',short_name:'SG',value:0},
          {show:true,name:'泰国',short_name:'TH',value:0},
          {show:true,name:'土耳其',short_name:'TR',value:0},
          {show:true,name:'中国台湾',short_name:'TW',value:0},
          {show:true,name:'乌兹别克斯坦',short_name:'UZ',value:0},
          {show:true,name:'越南',short_name:'VN',value:0},
        ]
      },
      {
        state:'北美洲',
        data:[
          {show:true,name:'加拿大',short_name:'CA',value:0},
          {show:true,name:'墨西哥',short_name:'MX',value:0},
          {show:true,name:'美国',short_name:'US',value:0},
        ]
      },
      {
        state:'大洋洲',
        data:[
          {show:true,name:'澳大利亚',short_name:'AU',value:0},
        ]
      },
      {
        state:'欧洲',
        data:[
          {show:true,name:'阿尔巴尼亚',short_name:'AL',value:0},
          {show:true,name:'奥地利',short_name:'AT',value:0},
          {show:true,name:'波黑',short_name:'BA',value:0},
          {show:true,name:'保加利亚',short_name:'BG',value:0},
          {show:true,name:'白俄罗斯',short_name:'BY',value:0},
          {show:true,name:'瑞士',short_name:'CH',value:0},
          {show:true,name:'捷克',short_name:'CZ',value:0},
          {show:true,name:'德国',short_name:'DE',value:0},
          {show:true,name:'丹麦',short_name:'DK',value:0},
          {show:true,name:'爱沙尼亚',short_name:'EE',value:0},
          {show:true,name:'芬兰',short_name:'FI',value:0},
          {show:true,name:'法国',short_name:'FR',value:0},
          {show:true,name:'克罗地亚',short_name:'HR',value:0},
          {show:true,name:'匈牙利',short_name:'HU',value:0},
          {show:true,name:'爱尔兰',short_name:'IE',value:0},
          {show:true,name:'冰岛',short_name:'IS',value:0},
          {show:true,name:'立陶宛',short_name:'LT',value:0},
          {show:true,name:'拉脱维亚',short_name:'LV',value:0},
          {show:true,name:'摩纳哥',short_name:'MC',value:0},
          {show:true,name:'摩尔多瓦',short_name:'MD',value:0},
          {show:true,name:'黑山',short_name:'ME',value:0},
          {show:true,name:'北马其顿',short_name:'MK',value:0},
          {show:true,name:'挪威',short_name:'NO',value:0},
          {show:true,name:'波兰',short_name:'PL',value:0},
          {show:true,name:'葡萄牙',short_name:'PT',value:0},
          {show:true,name:'塞尔维亚',short_name:'RS',value:0},
          {show:true,name:'斯诺文尼亚',short_name:'SI',value:0},
          {show:true,name:'斯诺伐克',short_name:'SK',value:0},
          {show:true,name:'圣马力诺',short_name:'SM',value:0},
          {show:true,name:'乌克兰',short_name:'UA',value:0},
          {show:true,name:'英国',short_name:'GB',value:0},
          {show:true,name:'欧盟',short_name:'EU',value:0},
        ]
      },
      {
        state:'南美洲',
        data:[
          {show:true,name:'智利',short_name:'AU',value:0},
        ]
      }
    ]
},
  getFree(){
    return ['international','internationalDetail','personalHome','applicants','applyWorks','apply','newlyBuild','applyDetail','worksDetail','myMessage','myMessageCenter','litigation','litigationDetail','journal','client','query','queryKeyword','queryDetail','queryIntcls','queryClass','queryGoods']
  },
  getBase(){
    return  ['approvalContacts','approvalForm','approvalList','domestic','archives','dealtMatterDetail','maintain','objectionDetails','reviewDetails','maintainArchives','patentDetails','worksDetails','tradDetails','upload','dealtMatter', 'review', 'objection', 'patent', 'works', 'software', 'work', 'websiteFiling']
  },
  getPro(){
    return  ['billDetail','bills','atlas', 'risk']
  },
  getIntl(){
    return  ['interMark']
  },
   convertTime(time) {
    return (
      new Date(time).getFullYear() +
      '-' +
      (new Date(time).getMonth() < 9 ? '0' + (new Date(time).getMonth() + 1) : new Date(time).getMonth() + 1) +
      '-' +
      (new Date(time).getDate() < 10 ? '0' + new Date(time).getDate() : new Date(time).getDate())
    )
  },
  /**
   * 获取下拉框数据
   * @param fields
   * @returns Object
   */
  async getSelects (...fields) {
    let data = this.getItem('selects');
    if (!data) {
      await request.post('/api/selects', { fields: fields }).then(res => {
        if (res && res.err_code === 0) {
          data = res.data
          this.setItem(SELECTS, data)
        }
      })
    } else {
      for (let f of fields) {
        if (!data[f]) {
          await request.post('/api/selects', { fields: [f] }).then(res => {
            if (res && res.err_code === 0) {
              data[f] = res.data[f]
            }
            this.setItem(SELECTS, data)
          })
        }
      }
    }
    return data
  },
  // 获取时间选择器快捷选择时间数据
  // @returns Array
  getShortcuts () {
    return [
      {
        text: "最近一周",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "最近一个月",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "最近三个月",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
        },
      },
    ]
  },
  getMouch(){
return  [{
  text: '本月',
  onClick(picker) {
    picker.$emit('pick', [new Date(), new Date()]);
  }
}, {
  text: '今年至今',
  onClick(picker) {
    const end = new Date();
    const start = new Date(new Date().getFullYear(), 0);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近六个月',
  onClick(picker) {
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - 6);
    picker.$emit('pick', [start, end]);
  }
}]
  },
  // 第一次进入页面的加载假数据
  // @returns Array
  getList (t = 10) {
    return Array(t).fill({ id: Math.floor((Math.random() * t) + 1) })
  },

  backTop() {
    const that = this;
    let ispeed = Math.floor(-that.scrollTop / 5);
    document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
  },

}

export default storage



