import Vue from 'vue'
//input带焦点
const focus = Vue.directive('focus',{
    inserted: function (el) {
        el.querySelector('input').focus()
    }
})
//点击其他地方隐藏现在的
const outside = Vue.directive('click-outside',{
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
})
//拖拽
const drag = Vue.directive('drag',{
    inserted(el) {
        let gap = 10 //缝隙：小于缝隙时，则吸附
        let parent = el.offsetParent

        el.onmousedown = function(event) {
            var eleEvent = event || window.event //event的兼容,同时得到clientX,的值
            var x = eleEvent.clientX - el.offsetLeft;
            var y = eleEvent.clientY - el.offsetTop;
            var left = 0;
            var top = 0;

            var boxer = document.querySelector(".boxer")
            document.onmousemove = function(eve) {
                left = eve.clientX - x
                top = eve.clientY - y

                // 左
                if (left <= gap) {
                    left = 0
                }

                //右
                if (left >= parent.offsetWidth - el.offsetWidth - gap) { //大于整个盒子的宽度-小盒子的宽度
                    left = parent.offsetWidth - el.offsetWidth
                }

                // 上
                if (top <= gap) {
                    top = 0
                }

                // 下
                if (top >= parent.offsetHeight - el.offsetHeight - gap) {
                    top = parent.offsetHeight - el.offsetHeight
                }

                el.style.left = left + "px";
                el.style.top = top + "px";

            };
            document.onmouseup = function() {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        };
    }
})
//省略号显示
const compareWidth = (el) => {
    // 如果没有超出宽度，即子<父 则移除tooltip
    if (el.querySelector('.ellipse-text') && el.querySelector('.ellipse-text').offsetWidth < el.offsetWidth) {
        const copyEl = el.parentNode; // 获取到目标节点的父节点
        const copySpan = el.querySelector('.ellipse-text'); // 获取到目标节点的子节点，即纯粹的span标签文案
        el.parentNode.removeChild(el); // 移除带有el-tooltip组件的节点
        copyEl.appendChild(copySpan); // 将纯粹的span标签文案整体追加到目标节点的父节点
    }
};
const showtip = Vue.directive('showtip', {
    // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
    bind: (el, binding) => {
        // bind的时候无法获取到已经带有ajax数据的DOM元素，宽度为0
    },
    // 被绑定元素插入父节点时调用 (仅保证父节点存在，但不一定已被插入文档中)。
    inserted: (el) => {
        compareWidth(el); // 可以获取到ajax数据的DOM元素，即真实的宽度
    },
    // 所在组件的 VNode 更新时调用，但是可能发生在其子 VNode 更新之前。指令的值可能发生了改变，也可能没有。但是你可以通过比较更新前后的值来忽略不必要的模板更新
    update: (el) => {
        compareWidth(el); // 可以获取到ajax数据的DOM元素，即真实的宽度
    },
});


const copy = Vue.directive("copy", {
    bind(el, { value }) {
        el.$value = value;
        el.handler = () => {
            el.style.position = 'relative';
            if (!el.$value) {
                // 值为空的时候，给出提示
                Vue.prototype.$message.warning('无复制内容');
                return
            }
            // 动态创建 textarea 标签
            const textarea = document.createElement('textarea');
            // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
            textarea.readOnly = 'readonly';
            textarea.style.position = 'absolute';
            textarea.style.top = '0px';
            textarea.style.left = '-9999px';
            textarea.style.zIndex = '-9999';
            // 将要 copy 的值赋给 textarea 标签的 value 属性
            textarea.value = el.$value
            // 将 textarea 插入到 el 中
            el.appendChild(textarea);
            // 兼容IOS 没有 select() 方法
            if (textarea.createTextRange) {
                textarea.select(); // 选中值并复制
            } else {
                textarea.setSelectionRange(0, el.$value.length);
                textarea.focus();
            }
            const result = document.execCommand('Copy');
            if (result) Vue.prototype.$message.success('复制成功！');
            el.removeChild(textarea);
        }
        el.addEventListener('click', el.handler); // 绑定点击事件
    },
    // 当传进来的值更新的时候触发
    componentUpdated(el, { value }) {
        el.$value = value;
    },
    // 指令与元素解绑的时候，移除事件绑定
    unbind(el) {
        el.removeEventListener('click', el.handler);
    },
});

export {focus,outside,drag,showtip,copy}