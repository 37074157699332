<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import storage from "@/utils/storage.js";



export default {
  data() {
    return {};
  },
}; 
</script>